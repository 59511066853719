import React from "react";
import Head from 'next/head';
import PropTypes from "prop-types";
import { ToastContainer, Zoom } from 'react-toastify';
import 'lazyframe/src/scss/lazyframe.scss';
import 'react-toastify/scss/main.scss';
import "../scss/main.scss";

export default function MyApp({ Component, pageProps }) {
    return (
        <>
            <Head>
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover" />
                <script src="https://player.vimeo.com/api/player.js"></script>
            </Head>
            <Component {...pageProps} />
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                transition={Zoom}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                limit={3}
                pauseOnHover={false}
            />
        </>
    )
}

MyApp.propTypes = {
    Component: PropTypes.func,
    pageProps: PropTypes.object
}